<template>
  <div  class="content ma-5 mb-16 px-0 px-sm-16  mx-sm-12" >
    <div class="text-h6 font-weight-bold pa-2 under-line">请选择收货地址</div>
    <v-list-item-group
        v-model="selected_address"
        active-class=""
        color="primary"
      >
        <v-list-item v-for="address in addresses" :key="address.id" :value="address.id">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              
                <v-row>
                  <v-col  cols="4" >
                    <div>{{address.lastname + address.firstname}}</div>
                    <div>{{address.mobile}}</div>
                  </v-col>
                  <v-col  class="mx-0 px-0" cols="5" >
                     {{address.address_info}}
                     <div>{{address.address}}</div>
                  </v-col>
                  <v-col v-if="!$vuetify.breakpoint.mobile">
                     中国大陆
                  </v-col>
                  <v-col class="mx-0 pa-0" cols="1"  >
                     <v-btn
                        class="ma-0 pa-0"
                        plain
                        @click.stop="updateAddress(address.id)"
                      >修改
                      </v-btn>
                  </v-col>
                </v-row>
             
            </v-list-item-content>
          </template>
        </v-list-item>
    </v-list-item-group>

    <div>
      <v-btn
          class="ma-1"
          plain
          @click="addressItem={};showAddressForm=!showAddressForm"
        >
          + 添加新地址
        </v-btn>
    </div>

    <div>
      <v-container v-if="showAddressForm">
        <v-row>
          <v-col cols="6" md="4" class="px-4">
            <v-text-field
              label="姓"
              required
              v-model="addressItem.lastName"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" class="px-4">
            <v-text-field
              label="名"
              required
              v-model="addressItem.firstName"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8" class="px-4">
            <v-select
              :items="country_list"
              v-model="select_country"
              item-text="title"
              item-value="id"
              label="目的"
            ></v-select>
          </v-col>
          <v-col cols="0" md="4" class="px-4">
          
          </v-col>
          <v-col cols="4" md="4" class="px-4">
            <v-select
              :items="province_list"
              v-model="addressItem.select_province"
              item-text="province_name"
              item-value="province_id"
              label="省份/直辖市"
              @change="province_change"
            ></v-select>
          </v-col>
          <v-col cols="4" md="4" class="px-4">
            <v-select
              :items="city_list"
              v-model="addressItem.select_city"
              item-text="city_name"
              item-value="city_id"
              label="城市"
              @change="city_change"
            ></v-select>
          </v-col>
          <v-col cols="4" md="4" class="px-4">
            <v-select
              :items="district_list"
              v-model="addressItem.select_district"
              item-text="district_name"
              item-value="district_id"
              label="地区"
            ></v-select>
          </v-col>
          <v-col cols="12" md="8" class="px-4">
            <v-text-field
              label="详细地址"
              required
              v-model="addressItem.address"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="0" md="4" class="px-4">
          
          </v-col>
          <v-col cols="6" md="4" class="px-4">
            <v-text-field
              label="邮编"
              required
              v-model="addressItem.code"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" class="px-4">
            <v-text-field
              label="电话"
              required
              v-model="addressItem.tel"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          

          <v-col cols="12" md="4"  class="px-4 offset-md-8">
            <v-btn  dark outlined  tile class="main-btn" @click="saveAddress">保存</v-btn>
          </v-col>
          
        </v-row>
      </v-container>
    </div>

    

    <div class="text-h6 font-weight-bold pa-2 under-line">发票信息</div>
    <div>
      <v-btn
          class="ma-1"
          plain
          @click="showInvoiceForm=!showInvoiceForm"
        >
          + 填写发票
        </v-btn>
    </div>
    <div>
      <v-container v-if="showInvoiceForm">
        
        <v-row>
          <v-col cols="12" md="4" class="px-4">
            <v-row>
              <v-col cols="12" md="12" class="px-4">
                <v-radio-group
                  v-model="invoiceItem.type"
                  row
                >
                  <v-radio
                    label="个人"
                    value="1"
                  ></v-radio>
                  <v-radio
                    label="单位"
                    value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" v-show="invoiceItem.type==2" class="px-4">
                <v-text-field
                  label="单位抬头"
                  required
                  v-model="invoiceItem.company"
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" v-show="invoiceItem.type==2" class="px-4">
                <v-text-field
                  label="纳税人识别号"
                  required
                  v-model="invoiceItem.tfn"
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="px-4">
            <v-row>
              <v-col cols="12" md="12" class="px-4">
                <v-text-field
                  label="收票人姓名"
                  required
                  v-model="invoiceItem.name"
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="px-4">
                <v-text-field
                  label="收票人地址"
                  required
                  v-model="invoiceItem.address"
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="px-4">
                <v-text-field
                  label="收票人电话"
                  required
                  v-model="invoiceItem.tel"
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
           
        </v-row>
          
    
      </v-container>
    </div>

    <div class="text-h6 font-weight-bold pa-2 under-line">您的支付方式</div>
    <v-container class="pa-4">
      <v-item-group
        v-model="selected_pay"
      >
        <v-row>
          <v-col
            cols="6"
            md="2"
            v-if="!inWeixinNow"
          >
            <v-item  v-slot="{ active,toggle }" value=2>
              <div class="paytype pa-2" :class={payactive:active}>
              <v-img
                src="../assets/alipay3.png"
                height="50"
                width="140"
                
                
                @click="toggle"
              >
              </v-img>
              </div>
            </v-item>
          </v-col>
          <v-col
            cols="6"
            md="2"
          >
            <v-item v-slot="{ active,toggle }" value=1>
              <div class="paytype px-1 py-2" :class={payactive:active}>
              <v-img
                src="../assets/WePayLogo.png"
                height="40"
                width="144"
                
                @click="toggle"
              >
              </v-img>
              </div>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-container>

    <div>
      <v-row>
        <v-col cols="12" md="4" class="px-4 offset-md-8 font-weight-bold">
          <v-row>
            <v-col cols="12">
              <div class="float-left">商品总价</div>
              <div class="float-right">{{pre_price}}</div>
            </v-col>
            <v-col cols="12">
              <div class="float-left">运费</div>
              <div class="float-right">{{shipping_price}}</div>
            </v-col>
            <v-col cols="12" class="pointer" >
              <div class="float-left">优惠券</div>
              <div class="float-right">{{coupon_title}}</div>
            </v-col>
          </v-row>
          <v-row v-if="coupons.length>0">
            <v-list-item-group
              v-model="selected_coupon"
              active-class=""
              style="width:100%"
              color="primary"
              @change="changecoupon"
            >
              <v-list-item v-for="coupon in coupons" :key="coupon.coupon_id" :value="coupon.coupon_id">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>

                  <v-col cols="12" class="my-2 px-8 py-0 font-weight-light" >
                    <div class="float-left">{{coupon.coupon_name}}</div>
                    <div class="float-right">{{coupon.money}}</div>
                  </v-col>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-col cols="12">
              <div class="float-left">实际支付</div>
              <div class="float-right">{{final_price}}</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="pb-8">
      <v-row>
         <v-col cols="12" md="4"  class="px-4 offset-md-8">
            <v-btn  dark outlined  tile class="main-btn" @click="creatOrder">支付订单</v-btn>
          </v-col>
      </v-row>
    </div>





  </div>
</template>

<script>

import userApi from '@/api/user'
import orderApi from '@/api/order'
import { mapState } from 'vuex'



export default {


  data: () => ({
    selected_coupon : 0,
    out_trade_no:'',
    inWeixinNow : true,
    showAddressForm : false,
    showInvoiceForm : false,
    selected_address : 0,
    selected_pay : "1",
    addresses : [],
    addressItem : {},
    invoiceItem : {},
    select_country :1,
    country_list : [{id:1,title:"中国大陆"}],
    province_list : [],
    city_list : [],
    district_list : [],
    city_alllist : [],
    district_alllist : [],
    coupons:[],

    pre_price:0,
    shipping_price:0,
    coupon_title:"0 个可用",
    final_price:0,
    checkPayTimer :  null

    

  
    //
  }),
  computed: {
    ...mapState({
      imgdomain: state => state.app.imgdomain,
      domain: state => state.app.domain,
      pre_order_info: state => state.order.pre_order_info,
      uid: state => state.user.uid,
    }),
  },
  created() {

    this.inWeixinNow = this.$util.inWeiXin()
    console.log(this.inWeixinNow)
    userApi.getarea().then(res => {
      this.province_list = res.data.province
      this.city_alllist = res.data.city
      this.district_alllist = res.data.district

    })
    
    userApi.getaddresses().then(res => {
      this.addresses = res.data.data

    })

    userApi.coupon().then(res => {
      this.coupons = res.data
      if (this.coupons.length>0) {
        this.coupon_title = this.coupons.length+" 个可用"
      }
      

    })
    

    this.sumTotalPrice()


    
  },
  methods: {

    sumTotalPrice() {
      let pre_price = 0
      let shipping_price = 0
      this.pre_order_info.forEach(order => {
        pre_price += order.price * order.num
      })
      pre_price = pre_price.toFixed(2)
      this.pre_price = pre_price
      this.shipping_price = shipping_price
      this.final_price = pre_price - shipping_price
      if (this.selected_coupon>0)
      {

      
        let coupon = this.coupons.filter(item => item.coupon_id == this.selected_coupon)
        if (coupon.length==1) {
          let couponprice = parseFloat(coupon[0].money)
          this.final_price = this.final_price - couponprice
        }
        
      }
    },
    homesearch() {
     
      console.log(this.selected_pay)
    },
    changecoupon() {
      this.sumTotalPrice()
    },
    saveAddress() {

      let params = this.addressItem
      userApi.addaddress(params).then(res => {
        if (res.code==0) {
          this.$message.success("保存收货地址成功！")
          this.addressItem = {}
          this.showAddressForm = false

          userApi.getaddresses().then(res => {
            this.addresses = res.data.data

          })

        } else {
          this.$message.warning("保存收货地址失败！")
        }
      })

    },
    creatOrder() {

      
      let goods_sku_list = []
      this.pre_order_info.forEach(order => {
        goods_sku_list.push(order.sku_id+":"+order.num)
      })
      goods_sku_list = goods_sku_list.join(",")
      let params = {
        'goods_sku_list' : goods_sku_list,
        'pay_type' : this.selected_pay,
        'address_id' : this.selected_address,
        'invoice' : this.invoiceItem,
        'coupon_id' : this.selected_coupon
      }

      let suid = this.$util.getSourceId()
      if (suid && suid!=this.uid) {
        params.source_uid = suid

      }


      orderApi.createorder(params).then(res => {

        let out_trade_no = res.data.out_trade_no
        let orderid = res.data.order_id

        this.out_trade_no = out_trade_no

        if (orderid == undefined) {
          this.$message.warning("提示："+res.message)
          return
        }
        // 一次性分销
        this.$util.clearSourceId()
        this.$router.push("/profile/orderdetail?paying=1&out_trade_no="+out_trade_no)
        

      })

    },
    checkPayStatus() {
      if (this.out_trade_no!="") {
        orderApi.paystatus({out_trade_no:this.out_trade_no}).then(res => {
           let status = res.code
           if (status==0) {
              this.$router.push("/payreturn?out_trade_no="+this.out_trade_no)
           } else {
             setTimeout(this.checkPayStatus,1000)
           }
        })
      }
    },
    province_change(id) {

      this.city_list = []
      this.city_alllist.forEach(item => {
        if (item.province_id == id){
          this.city_list.push(item)
        }
      })

    },
    city_change(id) {

      this.district_list = []
      this.district_alllist.forEach(item => {
        if (item.city_id == id){
          this.district_list.push(item)
        }
      })

    },
    updateAddress(id) {
      let info = this.addresses.filter(item => item.id==id).pop()

      this.province_change(info.province)
      this.city_change(info.city)

      this.addressItem = {
        lastName : info.lastname,
        firstName : info.firstname,
        select_province : info.province,
        select_city : info.city,
        select_district : info.district,
        address : info.address,
        code : info.zip_code,
        tel : info.mobile,
        id : info.id

      }
      
      this.showAddressForm = true

    }, 
    
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}
.under-line
{
  border-bottom:1px solid black;
}
.payactive{
  border:1px solid black !important;
}
.paytype {
  border:1px solid #ffffff;
  width:165px;
  height: 70px;
  cursor: pointer;
}
</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>